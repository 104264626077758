<template>
	<div class="projectSliderContainer" ref="sliderContainer">
		<div class="container">
			<div class="projectSlider__header hide1200">
				<div class="projectSlider--title h2 animationBlockFadeUp"><TextScrambler :text="title"/></div>
				<div class="projectSlider__info animationBlockFadeUp">
					<router-link to="/portfolio/" class="projectSlider__info">50+ проектов</router-link>
				</div>
			</div>

			<div class="projectSlider">
				<div class="projectSlider__counter animationBlockFadeUp">
					<div>
						<p class="projectSlider__counter--current" v-html="currentSlide"></p>
						<span class="projectSlider__counter--line"><!-- <span ref="progress" class="hide1200"></span><span ref="progress2" class="show1200"></span> --></span>
						<p class="projectSlider__counter--count" v-html="projectsItems.length"></p>
					</div>
				</div>

				<VueSlickCarousel class="projectSlider__slider animationBlockFadeUp" :style="{height: sliderHeight + 'px'}" ref="slider" v-bind="slickSettings" @init="onInitCarousel" @afterChange="afterChange" @beforeChange="beforeChange">
					<template v-for="(slide, key) in projectsItems">
						<div class="projectSlider__itemContainer">
							<div class="projectSlider__item--up">
								<router-link :to="slide.link" class="projectSlider__item" :class="[{imageHoverSlide: slide.sliderType == `slider`}, {videoSlide: slide.sliderType == `video`}, slide.sliderClass]" :data-slide="key" @mouseenter.native="infoClass = 'active'" @mouseleave.native="infoClass = ''" @mousemove.native="mouseMove($event)" v-if="slide.link">

									<div class="projectSlider__overlaySvg" v-if="slide.sliderImageOverlaySvg" v-html="slide.sliderImageOverlaySvg">
									</div>

									<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-if="slide.sliderType == `hover`">
										<span class="projectSlider__imageHover" v-lazy:background-image="slide.sliderImageHoverSrc"></span>
									</span>

									<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `slider`">
										<span class="projectSlider__imageHoverSlide">
											<span class="projectSlider__imageHoverSlide--image" v-lazy:background-image="slide.sliderImageHoverSrc"></span>
										</span>
									</span>

									<span class="projectSlider__video" v-else-if="slide.sliderType == `video` && !isMobile">
										<video :src="slide.sliderVideoSrc" muted="muted" playsinline loop="loop" autoplay></video>
									</span>
									
									<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `video`">
									</span>
									
									<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `image`"></span>

									<span class="projectSlider__img" v-else-if="slide.sliderType == `imageInner`">
										<img v-lazy="slide.sliderImageSrc">
									</span>
									
									<span class="projectSlider__img randomPosition noRandom" v-else-if="slide.sliderType == `randomPosition`">
										<img v-lazy="slide.sliderImageSrc">
									</span>

									<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else>
									</span>

									<div class="projectMouse" v-if="!mobileDevice">
										Перейти
									</div>

								</router-link>
								<div class="projectSlider__item" :class="[{imageHoverSlide: slide.sliderType == `slider`}, {videoSlide: slide.sliderType == `video`}, slide.sliderClass]" :data-slide="key" @mousemove="mouseMove($event)" @mouseenter="infoClass = 'active'" @mouseleave="infoClass = ''" v-else>

									<div class="projectSlider__overlaySvg" v-if="slide.sliderImageOverlaySvg" v-html="slide.sliderImageOverlaySvg">
									</div>

									<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-if="slide.sliderType == `hover`">
										<span class="projectSlider__imageHover" v-lazy:background-image="slide.sliderImageHoverSrc"></span>
									</span>

									<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `slider`">
										<span class="projectSlider__imageHoverSlide">
											<span class="projectSlider__imageHoverSlide--image" v-lazy:background-image="slide.sliderImageHoverSrc"></span>
										</span>
									</span>

									<span class="projectSlider__video" v-else-if="slide.sliderType == `video` && !isMobile">
										<video :src="slide.sliderVideoSrc" muted="muted" playsinline loop="loop"></video>
									</span>

									<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `video`">
									</span>
									
									<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `image`"></span>

									<span class="projectSlider__img" v-else-if="slide.sliderType == `imageInner`">
										<img v-lazy="slide.sliderImageSrc">
									</span>
									
									<span class="projectSlider__img randomPosition noRandom" v-else-if="slide.sliderType == `randomPosition`">
										<img v-lazy="slide.sliderImageSrc">
									</span>

									<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else>
									</span>

								</div>
							</div>
							<div class="projectSlider__tableInfo show1200">
								<div class="projectSlider__header">
									<div class="projectSlider--title h2 animationBlockFadeUp"><p v-html="slide.title"></p></div>
									<div class="projectSlider__info animationBlockFadeUp" :class="infoClass">
										<router-link :to="slide.link" v-if="slide.link">Подробнее</router-link>
									</div>
								</div>
								<div class="projectSlider--desc"><span v-html="slide.text"></span></div>
							</div>
						</div>
					</template>
				</VueSlickCarousel>

				<div class="projectSlider__arrows animationBlockFadeUp">
					<a class="projectSlider__arrows--left" @click="beforeSlide()"><span class="icon-arrow2-left"></span></a>
					<a class="projectSlider__arrows--right" @click="nextSlide()"><span class="icon-arrow2-right"></span></a>
				</div>
			</div>

			<div class="projectSlider__footer animationBlockFadeUp hide1200">
				<div class="projectSlider--desc"><span v-html="desc"></span></div>
				<div class="projectSlider__info animationBlockFadeUp" :class="infoClass">
					<router-link :to="url" v-if="url">Подробнее</router-link>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/ProjectSlider'
</style>

<script>
	import TextScrambler from 'text-scrambler';

	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
	import 	projectsData from "@/assets/json/projects.json";

	export default {
		data: () => ({
			slickSettings: {
				dots: false,
				arrows: false,
				infinite: true,
				useTransform: false,
				autoplay: false,
				autoplaySpeed: 10000
			},
			// isPause: false,
			// percentTime: 0,
			// time: 5,
			// tick: null,
			currentSlide: 1,
			title: "",
			desc: "",
			url: "",
			projectsData,
			projectsItems: [],
			isMobile: false,
			mobileDevice: false,
			paused: true,
			sliderHeight:0,
			infoClass: "",
			randomPositionInterval: null
		}),
		beforeMount(){
			this.initSlides();
		},
		mounted() {
			var comp = this;
			if(this.projectsItems[0]){
				this.title = this.projectsItems[0].title;
				this.desc = this.projectsItems[0].text;
				this.url = this.projectsItems[0].link;
			}

			comp.videoHeight();
			comp.resetSizes();
			comp.setIsMobile();
			comp.setSliderHeight();
			// comp.setRandomPosition();


			window.addEventListener('scroll', function(){
				var scrollTop = document.documentElement.scrollTop;
				var windowHeight = window.innerHeight;
				var windowWidth = window.innerWidth;
				var windowBottomPos = scrollTop + windowHeight;
				var element = comp.$refs["sliderContainer"];
				var elementHeight = element.offsetHeight;
				var elementPosTop = element.getBoundingClientRect().top + scrollTop;

				if(windowBottomPos > elementPosTop && scrollTop < elementPosTop + elementHeight){
					comp.startSlider();
				} else{
					comp.stopSlider();
				}
			});

			window.addEventListener('resize', function(){
				comp.setSlidesWidth();
				comp.resetSizes();
				comp.setIsMobile();
				comp.setSliderHeight();
				comp.videoHeight();
			});

			setTimeout(function(){
				comp.setSliderHeight();
				comp.videoHeight();
			}, 1000);

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
				comp.mobileDevice = true;
			}
		},
		destroyed() {

		},
		methods: {
			setSliderHeight: function(){
				var comp = this;
				var slidesContainer = this.$el.getElementsByClassName("slick-slide");
				var slides = this.$el.getElementsByClassName("projectSlider__itemContainer");
				 comp.sliderHeight = 0;

				slidesContainer.forEach( function(element, index) {
					element.style.WebkitTransition = 'all 0s ease 0s';
					element.style.MozTransition = 'all 0s ease 0s';
					element.style.width = "100%";
				});

				slides.forEach( function(element, index) {
					var slideHeight = element.offsetHeight;
					if(slideHeight > comp.sliderHeight){
						comp.sliderHeight = slideHeight;
					}
				});

				slidesContainer.forEach( function(element, index) {
					if(element.classList.contains('slick-active')){
						element.style.width = "100%";
					} else{
						element.style.width = "0%";
					}
				});
			},
			setIsMobile: function(){
				if(document.body.clientWidth > 600){
					this.isMobile = false;
				} else{
					this.isMobile = true;
				}
			},
			initSlides: function(){
				var comp = this;

				comp.projectsData.forEach( function(element, index) {
					if(element.showInSlider){
						if(element.sliderImage){
							element.sliderImageSrc = require(`@/assets/img_new/${element.sliderImage}`);
						}
						if(element.sliderImageHover){
							element.sliderImageHoverSrc = require(`@/assets/img_new/${element.sliderImageHover}`);
						}
						if(element.sliderVideo){
							element.sliderVideoSrc = require(`@/assets/img_new/${element.sliderVideo}`);
						}
						comp.projectsItems.push(element);
					}
				});
			},
			onInitCarousel: function() {
				var comp = this;
				setTimeout(function(){
					// if(comp.tick){
					// 	clearTimeout(comp.tick);
					// }
					// comp.isPause = false;
					// comp.tick = setInterval(function(){
					// 	if (comp.isPause === false) {
					// 		comp.percentTime = comp.percentTime + (1 / (comp.time + 0.1));
					// 		comp.$refs["progress"].style.height = comp.percentTime + "%";
					// 		comp.$refs["progress2"].style.width = comp.percentTime + "%";

					// 		if(comp.percentTime >= 100){
					// 			comp.$refs["slider"].next();
					// 			comp.percentTime = 0;
					// 		}
					// 	}
					// }, 10);

					comp.setSlidesWidth();
				});
			},
			beforeChange: function(oldSlideIndex, newSlideIndex){
				// this.percentTime = 0;
				this.currentSlide = newSlideIndex + 1;

				if(this.projectsItems[newSlideIndex]){
					this.title = this.projectsItems[newSlideIndex].title;
					this.desc = this.projectsItems[newSlideIndex].text;
					this.url = this.projectsItems[newSlideIndex].link;
				}

				this.isPause = true;
				// this.percentTime = 0;
				// this.$refs["progress"].style.height = "0%";
				// this.$refs["progress2"].style.width = "0%";

				var oldSlide = this.$refs["slider"].$el.querySelectorAll("[data-index='"+ oldSlideIndex +"']")[0];
				var newSlide = this.$refs["slider"].$el.querySelectorAll("[data-index='"+ newSlideIndex +"']")[0];

				oldSlide.style.zIndex = '2';
				newSlide.style.WebkitTransition = 'all 0s ease 0s';
				newSlide.style.MozTransition = 'all 0s ease 0s';
				newSlide.style.width = '0%';
				newSlide.style.zIndex = '3';
				if(oldSlideIndex + 1 == newSlideIndex || (oldSlideIndex - 1 != newSlideIndex && oldSlideIndex > newSlideIndex)){
					newSlide.style.right = "0px";
					newSlide.style.left = "auto";
					newSlide.getElementsByClassName("projectSlider__item")[0].style.right = "0px";
					newSlide.getElementsByClassName("projectSlider__item")[0].style.left = "auto";
				} else{
					newSlide.style.right = "auto";
					newSlide.style.left = "0px";
					newSlide.getElementsByClassName("projectSlider__item")[0].style.right = "auto";
					newSlide.getElementsByClassName("projectSlider__item")[0].style.left = "0px";
				}
				
				setTimeout(function(){
					newSlide.style.WebkitTransition = 'all 0.4s ease 0s';
					newSlide.style.MozTransition = 'all 0.4s ease 0s';
					setTimeout(function(){
						newSlide.style.width = '100%';
						setTimeout(function(){
							oldSlide.style.zIndex = '1';
						}, 400);
					});
				}, 10);

				// if(newSlide.getElementsByClassName("randomPosition").length){
				// 	this.setRandomPosition();
				// }
			},
			afterChange: function(slideIndex){
				// this.percentTime = 0;
				// this.isPause = false;
				this.currentSlide = slideIndex + 1;

				var videos = this.$refs["slider"].$el.getElementsByTagName("video");
				videos.forEach(function(element, index) {
					var slide = element.closest(".projectSlider__item").dataset.slide;
					if(slide == slideIndex){
						element.play();
					} else{
						element.pause();
					}
				});
			},
			nextSlide: function(){
				this.$refs["slider"].next();
				this.$refs["slider"].play();
			},
			beforeSlide: function(){
				this.$refs["slider"].prev();
				this.$refs["slider"].play();
			},
			setSlidesWidth: function(){
				var width = this.$refs["slider"].$el.offsetWidth;
				this.$refs["slider"].$el.getElementsByClassName("projectSlider__item").forEach( function(element, index) {
					element.style.width = width + "px";
				});
			},
			mouseMove: function(event){
				var target = event.target;
				var element;
				
				if(target.classList.contains('imageHoverSlide')){
					element = target;
				} else{
					element = target.closest('.projectSlider__item');
				}

				if(target.classList.contains('imageHoverSlide') || (target.closest('.projectSlider__item') && target.closest('.projectSlider__item').classList.contains('imageHoverSlide'))){

					var image = element.getElementsByClassName("projectSlider__img")[0];
					var imageSlide = element.getElementsByClassName("projectSlider__imageHoverSlide")[0];

					var mousePos = event.pageY;

					var bodyRect = document.body.getBoundingClientRect(),
					elemRect = element.getBoundingClientRect();
					var blockTop = elemRect.top - bodyRect.top;

					var height = image.offsetHeight;

					var result = (mousePos - blockTop) / height * 100;
					imageSlide.style.height = result + "%";
				}

				if(!this.mobileDevice){
					var mouseElement = element.getElementsByClassName("projectMouse")[0];

					var targetCoords = element.getBoundingClientRect();
					var xCoord = event.clientX - targetCoords.left;
					var yCoord = event.clientY - targetCoords.top;

					setTimeout(function(){
						mouseElement.style.top = yCoord + "px";
						mouseElement.style.left = xCoord + "px";
					}, 100);
				}
			},
			videoHeight: function(){
				var comp = this;
				setTimeout(function(){
					var videoContainers = comp.$el.getElementsByClassName("videoSlide");
					
					Array.from(videoContainers).forEach(function(el, index){
						if(el.getElementsByTagName("video").length){
							var video = el.getElementsByTagName("video")[0];
							video.style.height = 'auto';
							video.style.width = 'auto';

							var containerHeight = el.offsetHeight;
							var containerWidth = el.offsetWidth;
							var videoHeight = video.offsetHeight;
							var videoWidth = video.offsetWidth;

							if(containerHeight/videoHeight > containerWidth/videoWidth){
								video.style.height = (containerHeight+10) + "px";
							} else{
								video.style.width = (containerWidth+10) + "px";
							}
						}
					});
				});
			},
			resetSizes: function(){
				var elements = document.getElementsByClassName("projectSlider__imageHoverSlide--image");
				elements.forEach( function(element, index) {
					var parent = element.closest(".projectSlider__item");
					var parentHeight = parent.offsetHeight;
					element.style.height = parentHeight + "px";
				});
			},
			startSlider: function(){
				if(this.paused){
					this.$refs["slider"].play();
				}
				this.paused = false;
			},
			stopSlider: function(){
				if(!this.paused){
					this.$refs["slider"].pause();
				}
				this.paused = true;
			},
			setRandomPosition: function(){
				var comp = this;

				clearInterval(comp.randomPositionInterval);
				comp.randomPositionInterval = null;

				comp.randomPositionInterval = setInterval(function(){
					var elements = document.getElementsByClassName("randomPosition");

					elements.forEach( function(element, index) {
						var image = element.getElementsByTagName("img")[0];
						var parent = element.closest(".projectSlider__item");
						var parentHeight = parent.offsetHeight - 40;
						var parentWidth = parent.offsetWidth - 40;
						image.style.maxHeight = "inherit";
						image.style.maxWidth = "inherit";
						var elementHeight = image.offsetHeight;
						var elementWidth = image.offsetWidth;

						if(elementHeight > parentHeight){
							elementHeight = parentHeight;
						}

						if(elementWidth > parentWidth){
							elementWidth = parentWidth;
						}

						var height = Math.random() * elementHeight;
						if(height < 250){
							height = 250;
						}

						var width = Math.random() * elementWidth;
						if(width < 250){
							width = 250;
						}

						var top = Math.random() * (parentHeight - elementHeight) + 20;
						var left = Math.random() * (parentWidth - elementWidth) + 20;

						image.style.maxHeight = height + "px";
						image.style.maxWidth = width + "px";

						if(Math.random() < 0.5){
							image.style.bottom = "auto";
							image.style.top = top + "px";
						} else{
							image.style.top = "auto";
							image.style.bottom = top + "px";
						}

						if(Math.random() < 0.5){
							image.style.right = "auto";
							image.style.left = left + "px";
						} else{
							image.style.left = "auto";
							image.style.right = left + "px";
						}
					});
				}, 3000);
			}
		},
		beforeDestroy: function () {
			var comp = this;
			if(comp.tick){
				clearTimeout(comp.tick);
			}
		},
		components: {
			VueSlickCarousel,
			TextScrambler
		}
	}
</script>
